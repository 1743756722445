<template>
  <div>
    <pageTitle pageTitle="虚拟人数">
      <div slot="content">
        1.人数：当前在线人数
        <br />
        2.热度：创建至今，进入观看页面（直播和回放、点播）的浏览量
      </div>
    </pageTitle>
    <!-- 模式选择 -->
    <div class="set-box">
      <vh-tabs v-model="tabType" @tab-click="handleClick">
        <vh-tab-pane label="观看数据" name="viewerCount"></vh-tab-pane>
        <vh-tab-pane label="点赞数据" name="likesCount"></vh-tab-pane>
      </vh-tabs>
      <div class="virtual-ctx">
        <vh-form
          v-if="tabType == 'viewerCount'"
          :model="virtualForm"
          ref="virtualForm"
          :rules="virtualFormRules"
          label-width="100px"
          width="360px"
        >
          <vh-form-item label="人数增加" prop="online">
            <vh-input
              autocomplete="off"
              v-model.trim="virtualForm.online"
              placeholder="请输入1-999999之间正整数"
              class="btn-relative btn-two"
              @input="formatInputs($event, 'virtualForm', 'online')"
            >
              <vh-button type="text" class="no-border" size="mini" slot="append">人</vh-button>
            </vh-input>
          </vh-form-item>
          <vh-form-item label="热度增加" prop="pv">
            <vh-input
              autocomplete="off"
              v-model.trim="virtualForm.pv"
              placeholder="热度不小于观看人数，且不超过999999"
              class="btn-relative btn-two"
              @input="formatInputs($event, 'virtualForm', 'pv')"
            >
              <vh-button type="text" class="no-border" size="mini" slot="append">次</vh-button>
            </vh-input>
          </vh-form-item>
          <vh-form-item>
            <div class="notice">
              <p>提示：</p>
              <p>1.控制台保存成功后，观看页将在真实数据基础上立即生效设置的虚拟人数</p>
              <p>2.直播间设置虚拟人数，将会模拟真实场景逐渐累加数据，请按需使用</p>
              <p>3.活动重复开播，需要重新设置虚拟人数才能生效</p>
            </div>
          </vh-form-item>
          <vh-form-item label="">
            <vh-button type="primary" class="length152" @click.prevent.stop="virtualSetSave" round>
              保 存
            </vh-button>
          </vh-form-item>
        </vh-form>
        <div class="like-content" v-if="tabType == 'likesCount'">
          <div class="like-info">
            <div class="like-item">
              <p class="count">{{ likesInfo.virtual_count + likesInfo.total }}</p>
              <p>当前点赞数</p>
            </div>
            <div class="like-item">
              <p class="count">{{ likesInfo.total }}</p>
              <p>实际点赞数</p>
            </div>
            <vh-button type="text" @click="getLikesInfo">
              刷新数据
              <i class="vh-icon-refresh-right"></i>
            </vh-button>
          </div>
          <vh-form
            :model="virtualLikesForm"
            ref="likesForm"
            :rules="likesFormRules"
            label-width="100px"
            width="360px"
          >
            <vh-form-item label="点赞数增加" prop="add_virtual_like_count">
              <vh-input
                autocomplete="off"
                v-model.trim="virtualLikesForm.add_virtual_like_count"
                placeholder="请输入1-999999之间正整数"
                class="btn-relative btn-two"
                @input="formatInputs($event, 'virtualLikesForm', 'add_virtual_like_count')"
              >
                <vh-button type="text" class="no-border" size="mini" slot="append">次</vh-button>
              </vh-input>
            </vh-form-item>
            <vh-form-item label="点赞倍数">
              <vh-select
                autocomplete="off"
                v-model="virtualLikesForm.like_times"
                placeholder="请选择点赞倍数"
                class="btn-relative btn-two"
              >
                <vh-option v-for="m in multiples" :key="m" :value="m" :label="'x ' + m">
                  {{ 'x ' + m }}
                </vh-option>
              </vh-select>
              倍
            </vh-form-item>
            <vh-form-item>
              <div class="notice">
                <p>提示：</p>
                <p>1.当前点赞数：实际点赞数+添加点赞数以及设置点赞倍数后的数据</p>
                <p>2.实际点赞数：观众真实点赞次数</p>
                <p>3.控制台保存成功后，观看页将在真实数据基础上生效设置的点赞数据</p>
                <p>4.活动重复开播，需要重新设置才能生效</p>
              </div>
            </vh-form-item>
            <vh-form-item label="">
              <vh-button type="primary" class="length152" @click.prevent.stop="likesSetSave" round>
                保 存
              </vh-button>
            </vh-form-item>
          </vh-form>
        </div>
      </div>
    </div>
    <begin-play
      :webinarId="$route.params.str"
      v-if="$route.query.type != 5 && webinarState != 4"
    ></begin-play>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import beginPlay from '@/components/beginBtn';
  import { sessionOrLocal } from '@/utils/utils';
  import regRule from '@/utils/reg-rule.js';
  export default {
    name: 'virtual.vue',
    components: {
      PageTitle,
      beginPlay
    },
    data() {
      // 校验人数
      let checkOnlineNum = (rule, value, callback) => {
        // 在线人数不能超过热度的80%
        let pvCount = value > 0 ? Math.floor(Number(this.virtualForm.pv) * 0.8) : 0;
        if (!regRule.num7.test(value)) {
          return callback(new Error('请输入1~999,999之间的正整数'));
        } else if (this.virtualForm.pv > 0 && pvCount < Number(value)) {
          return callback(new Error('在线人数不能超过热度的80%'));
        } else {
          if (this.virtualForm.pv !== '') {
            this.$refs.virtualForm.clearValidate('pv');
          }
          callback();
        }
      };
      // 校验热度
      let checkPv = (rule, value, callback) => {
        // 在线人数不能超过热度的80%
        let pvCount = value > 0 ? Math.floor(Number(value) * 0.8) : 0;
        if (!regRule.num7.test(value)) {
          return callback(new Error('请输入1~999,999之间的正整数'));
        } else if (this.virtualForm.online > 0 && pvCount < Number(this.virtualForm.online)) {
          return callback(new Error('在线人数不能超过热度的80%'));
        } else {
          if (this.virtualForm.online !== '') {
            this.$refs.virtualForm.clearValidate('online_num');
          }
          callback();
        }
      };
      return {
        webinarState: JSON.parse(sessionOrLocal.get('webinarState')),
        virtualForm: {
          pv: null, // pv
          online: null // 在线人数
        },
        virtualLikesForm: {
          webinar_id: this.$route.params.str,
          add_virtual_like_count: '', //虚拟点赞数
          like_times: 1 //点赞倍数
        },
        virtualFormRules: {
          online: [{ validator: checkOnlineNum, trigger: 'blur' }],
          pv: [{ validator: checkPv, trigger: 'blur' }]
        },
        multiples: Array.from({ length: 10 }, (v, k) => k + 1),
        likesFormRules: {
          add_virtual_like_count: [
            {
              validator: (rule, value, callback) => {
                if (value && !regRule.num7.test(value)) {
                  return callback(new Error('请输入1~999,999之间的正整数'));
                } else {
                  callback();
                }
              },
              trigger: 'blur'
            }
          ]
        },
        likesInfo: {
          total: 0,
          virtual_count: 0
        },
        tabType: 'viewerCount'
      };
    },
    watch: {
      $route: {
        handler: function (route) {
          let levelList = [
            {
              title: '直播管理',
              path: '/live/list',
              isClick: false
            },
            {
              title: '直播列表',
              path: '/live/list',
              isClick: true
            },
            {
              title: '直播列表',
              path: `/live/detail/${this.$params.str}`,
              isClick: true
            },
            {
              title: '观看限制',
              path: `/live/viewerRules/${this.$params.str}`,
              isClick: false
            }
          ];
          this.$EventBus.$emit('saas_vs_crumb_event', this.levelList);
        },
        immediate: true
      }
    },
    methods: {
      formatInputs(value, formName, key) {
        if (!regRule.num7.test(value)) {
          this[formName][key] = '';
        } else {
          this[formName][key] = parseInt(value);
        }
      },
      // 获取虚拟人数信息状态
      getVirtualInfo() {
        this.$fetch('virtualGet', {
          webinar_id: this.$route.params.str
        })
          .then(res => {
            console.log(res);
            if (res.data) {
              res.data.pv = res.data.pv === 0 ? null : res.data.pv;
              res.data.online = res.data.online === 0 ? null : res.data.online;
              this.virtualForm = res.data;
            } else {
              this.virtualForm = {
                pv: null,
                online: null
              };
            }
          })
          .catch(err => {
            console.log(err);
            this.virtualForm = {
              pv: null,
              online: null
            };
          });
      },
      // 虚拟人数设置保存
      virtualSetSave() {
        let userId = JSON.parse(sessionOrLocal.get('userId'));
        this.$refs.virtualForm.validate(valid => {
          if (valid) {
            let params = {
              webinar_id: this.$route.params.str,
              pv: this.virtualForm.pv,
              online: this.virtualForm.online
            };
            this.$fetch('virtualSetSave', params)
              .then(res => {
                this.$vhall_paas_port({
                  k: 100136,
                  data: {
                    business_uid: userId,
                    user_id: '',
                    webinar_id: this.$route.params.str,
                    refer: '',
                    s: '',
                    report_extra: {},
                    ref_url: '',
                    req_url: ''
                  }
                });
                this.$vhMessage({
                  message: `设置成功`,
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                this.getVirtualInfo();
              })
              .catch(res => {
                console.log(res);
                this.$vhMessage({
                  message: res.msg || `设置失败`,
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              });
          }
        });
      },
      //虚拟点赞设置
      likesSetSave() {
        this.$refs.likesForm.validate(valid => {
          if (valid) {
            this.$fetch('likesSetSave', this.virtualLikesForm)
              .then(res => {
                this.$vhMessage({
                  message: `设置成功`,
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                this.getLikesInfo();
              })
              .catch(res => {
                console.log(res);
                this.$vhMessage({
                  message: res.msg || `设置失败`,
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              });
          }
        });
      },
      getLikesSet() {
        this.$fetch('likesGet', { webinar_id: this.$route.params.str }).then(res => {
          this.virtualLikesForm.add_virtual_like_count = res.data.add_virtual_like_count || '';
          this.virtualLikesForm.like_times = res.data.like_times || '';
        });
      },
      getLikesInfo() {
        const liveDetailInfo = JSON.parse(sessionOrLocal.get('liveDetailInfo'));
        this.$fetch('getLikesInfo', { room_id: this.$route.query.roomId }).then(res => {
          this.likesInfo.total = res.data.total || 0;
          this.likesInfo.virtual_count = res.data.virtual_count || 0;
        });
      },
      handleClick() {
        if (this.tabType == 'likesCount') {
          this.getLikesInfo();
          this.getLikesSet();
        }
      }
    },
    created() {
      this.getVirtualInfo();
    }
  };
</script>

<style lang="less" scoped>
  .set-box {
    .layout--right--main;
  }
  .virtual-ctx {
    .padding41-40;
    .min-height;
    .btn-relative {
      position: relative;
    }
  }
  ::v-deep .vh-input {
    width: 400px;
  }
  ::v-deep .vh-select .vh-input {
    width: 100px;
  }
  .notice {
    p {
      font-size: 12px;
      color: #999999;
      margin: 0 0;
      line-height: 20px;
    }
  }
  .like-content {
    .like-info {
      display: flex;
      padding-left: 100px;
      width: 500px;
      text-align: center;
      padding-bottom: 40px;
      justify-content: space-between;
      .like-item {
        padding-right: 30px;
        p {
          text-align: center;
          font-size: 12px;
          color: #999;
          &.count {
            font-size: 24px;
            color: #262626;
            font-weight: 500;
            padding-bottom: 5px;
          }
        }
      }
    }
  }
</style>
<style>
  .virtual-ctx .vh-input-group__append {
    text-align: center;
  }
</style>
